import React from 'react';

import Layout from '../../components/Layout';
import Feature from '../../components/Feature';
import theme from '../../theme';
import IdeaboxIcon from '../../images/idea.jpg';
import B1 from '../../images/new/bai_fct1.jpg';
import B2 from '../../images/new/bai_fct2.jpg';
import B3 from '../../images/new/bai_fct3.jpg';

const IdeaboxPage = () => (
  <Layout>
    <Feature
      next="/features/lab"
      color={theme.palette.secondary.main}
      title="Boite à idées"
      icon={IdeaboxIcon}
      subtitle="Tout le monde a des idées, tout le temps et à tous les niveaux hiérarchiques."
      description="Le meilleur moyen de leur donner une chance de voir le jour est de les partager pour recueillir
      rapidement un maximum d'avis au sein de toute l’organisation."
      subSections={[
        'Diversifier la nature de vos projets d’innovation',
        'Créer du lien entre différentes équipes',
        'Booster la créativité et l’inventivité',
      ]}
      sections={[
        {
          title: 'Création de contributions',
          description:
            'Vos collaborateurs publient facilement une idée, un problème ou une inspiration qu’ils ont au quotidien.',
          image: B1,
        },
        {
          title: 'Votes et commentaires',
          description:
            'Vos collaborateurs votent et commentent les contributions de leurs collègues pour les faire évoluer.',
          image: B2,
        },
        {
          title: 'Filtrer et trier vos contributions',
          description:
            'Recherchez toutes les idées par mots clés. Filtrez-les par catégorie ou tag. Triez-les par popularité ou par date.',
          image: B3,
        },
      ]}
    />
  </Layout>
);

export default IdeaboxPage;
